import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Zipomega';
  titleKey = 'title';

  constructor(
    private titleService: Title,
    private readonly meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    AOS.init();
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data[this.titleKey]) {
            this.seoService(child.snapshot.data);
            return child.snapshot.data[this.titleKey];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(`${ttl} - ${this.title}`);
      });
  }

  seoService(meta: any) {
    if (meta.ogURL) {
      this.meta.updateTag({ name: 'og:url', content: meta.ogURL });
    }
    if (meta.desc) {
      this.meta.updateTag({ name: 'description', content: meta.desc });
    }
  }
}
