import { ErrorHandler, Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private analytics: AngularFireAnalytics) {}

    handleError(error: any): void {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (chunkFailedMessage.test(error.message)) {
            this.analytics.logEvent('Application_Load_Error_Event', {
                applicationLoaded: false,
            });
            window.location.reload();
        }
    }
}
