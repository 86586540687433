// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  useHash: true,
  firebaseConfig : {
    apiKey: 'AIzaSyBe_Zi8nM_zT7ijslICBR2zAuR53pkWPWg',
    authDomain: 'zipomega-d24d0.firebaseapp.com',
    projectId: 'zipomega-d24d0',
    storageBucket: 'zipomega-d24d0.appspot.com',
    messagingSenderId: '753318973593',
    appId: '1:753318973593:web:bc3c56b1d890941990a808',
    measurementId: 'G-W1JNNLGTYY'
  },
  database: {
    contact: 'contact-test'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
